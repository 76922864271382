.codeInput-wrapper {
    margin-bottom: 12px;
}
.codeInput-ref {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
}

.codeInput-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 32px;
}

.codeInput-digit {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    font-size: 32px;
    font-weight: bold;
    text-align: center;
}

.codeInput-digit:nth-child(3) {
    margin-right: 16px;
}

.codeInput-error>.codeInput-digit {
    border: 1px solid #FF2121;
    color: #FF2121;
    animation: shake 0.3s ease-in-out;
}

.codeInput-success>.codeInput-digit {
    color: #1CC052;
    border: 1px solid #1CC052;
}

.focused {
    border: 2px solid black;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    75% {
        transform: translateX(5px);
    }
}