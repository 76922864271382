.card-wrapper {
  padding: 4px;
  width: 72px;
  height: 44px;
  border-radius: 8px;
  cursor: pointer;
}

.text {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #000;
}

.add-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  padding: 16px;
  width: 163.5px;
  min-height: 112px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}


.add-card_footer {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
}
