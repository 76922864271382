.wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    background: var(--radial, radial-gradient(141.42% 141.42% at 100% 0%, #E5FEFF 0%, #E5F1FF 60.48%, #E8E5FF 100%));
    .card {
      margin: 20px;
      max-width: 500px;
      width: 100%;
      .companyName {
        text-align: center;
        font-size: 32px;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;
        margin-bottom: 24px;
        user-select: none;
      }
      .title {
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 8px;
      }
      .desc {
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }
  