.wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    background: var(--radial, radial-gradient(141.42% 141.42% at 100% 0%, #E5FEFF 0%, #E5F1FF 60.48%, #E8E5FF 100%));
}
.card {
  margin: 20px;
  max-width: 400px;
  width: 100%;
}

.icon {
  width: 78px;
  height: auto;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  
  &-error{
    @extend .icon;
    path {
      fill: #ff2121;
    }
  }
  &-success{
    @extend .icon;
    rect {
      fill: #1EC624;
    }
  }
}





.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
  text-align: center;
}

.desc{
    @extend .text;
    margin-bottom: 20px;
}