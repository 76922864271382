.wrapper {
  width: 560px;
  margin-top: 40px;
}

.segmented {
  div {
    border-radius: 100px !important;
  }

  label {
    background-color: rgba(0, 0, 0, 0.05);
    margin-right: 8px;
    padding: 10px 16px;
    font-size: 14px;
    border-radius: 100px !important;

    div {
      line-height: 1;
      min-height: unset;
      padding: 0;
    }
  }
}