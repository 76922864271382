.text {
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
}

.button {
    margin-top: 16px;
}

.segmented {
    margin-bottom: 32px;
}

.hint {
    margin-top: 4px;
    font-size: 14px;
}

.link {
    display: contents;
}