.ant-btn {
    height: auto !important;
}
.ant-input-number {
    width: 100%;
}
.ant-form-item-explain {
    margin-bottom: 8px;
}
.ant-checkbox-wrapper {
    align-items: center;
}