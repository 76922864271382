.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 800;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  .titleContainer {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 500;
      font-size: 32px;
      font-family: 'Lora', sans-serif;
    }
  }
  .centered {
    display: flex;
    align-items: center;
  }

  .button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.5s;

    &:hover {
      background-color: #e1e1e1;
      transition: background-color 0.5s;
    }

    span {
      font-size: 24px;
      color: black;
    }
  }
}
@media screen and (max-width: 1024px) {
  .container {
    padding: 16px;
    .titleContainer {
      .title {
        font-size: 24px;
      }
    }
  }
}
