:root {
  --primary: #1aaee5;
}

html, body, #root {
  width: 100%;
  height: 100%;
}
html {
  background: #fff;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}
body {
  scroll-behavior: smooth;
}
@media screen and (max-width: 480px) {
  html {
    font-size: 12px;
  }
}