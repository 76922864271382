.title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    color: #000;
}

.text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #000;
}