.text {
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    line-height: 20px;
}

.number {
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
}