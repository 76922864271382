@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../assets/fonts/Lora/Lora-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-HeavyItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-SemiboldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-Ultralight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../assets/fonts/SFProDisplay/SF-Pro-Display-UltralightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}
