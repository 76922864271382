.text{
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
}

.headerTitle{
    text-align: center;
    font-size: 20px;
}

.headerTitle > div{
    font-size: 20px !important;
}

.icon{
    display: block;
    text-align: center;
    margin: 20px auto;
    width: 80px;
    height: 80px;
    color: #FFCC00;
}