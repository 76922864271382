.wrapper {
    padding: 32px 0;
}

.text {
    vertical-align: bottom;
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
}

.button {
    cursor: pointer;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.button:hover {
    transform: translateX(-8px);
}