.container {
  padding: 24px 60px;
}

@media screen and (min-width: 1681px) {
  /* CSS правила для экстра больших рабочих столов */
}
@media screen and (max-width: 1680px) {
  /* CSS правила для очень больших рабочих столов */
}
@media screen and (max-width: 1440px) {
  /* CSS правила для больших рабочих столов */
}
@media screen and (max-width: 1024px) {
  /* CSS правила для планшетов в ландшафтном режиме */
  .container {
    padding: 24px 16px;
  }
}
@media screen and (max-width: 768px) {
  /* CSS правила для планшетов в портретном режиме */
}
@media screen and (max-width: 480px) {
  /* CSS правила для мобильных устройств */
}




// @media screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
//  /* CSS правила для экранов с высоким разрешением */
//}
//@media screen and (min-width: 1681px) {
//  /* CSS правила для экстра больших рабочих столов */
//}
//@media screen and (min-width: 1441px) and (max-width: 1680px) {
//  /* CSS правила для очень больших рабочих столов */
//}
//@media screen and (min-width: 1281px) and (max-width: 1440px) {
//  /* CSS правила для больших рабочих столов */
//}
//@media screen and (min-width: 1025px) {
//  /* CSS правила для рабочих столов */
//}
//@media screen and (min-width: 769px) and (max-width: 1024px) {
//  /* CSS правила для планшетов в ландшафтном режиме */
//  .container {
//    padding: 24px 16px;
//  }
//}
//@media screen and (min-width: 481px) and (max-width: 768px) {
//  /* CSS правила для планшетов в портретном режиме */
//  .container {
//    padding: 24px 16px;
//  }
//}
//@media screen and (max-width: 480px) {
//  /* CSS правила для мобильных устройств */
//  .container {
//    padding: 24px 16px;
//  }
//}
