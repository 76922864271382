.card {
  background-color: #0000000D;
  cursor: pointer;
  transition: 0.2s;

  &__subtitle {
    font-size: 14px;
    color: #00000099;
    line-height: 18px;
  }

  &__amount {
    font-size: 18px;
    line-height: 20px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.card_disabled {
  cursor: not-allowed;

  .card__subtitle, .card__amount, img {
    opacity: 0.5;
  }
}