.edit-btn {
  padding: 12px 32px;
  border-radius: 16px;
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  line-height: 22px;
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 20px;
}

.pen-icon {
  background-color: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
  transition: background-color 0.2s;

  svg {
    fill: black;
    transition: fill 0.2s;
  }

  &:hover {
    svg {
      fill: #6c6c6c;
      transition: fill 0.2s;
    }
  }
}