$borderRadius: 24px;

.wrapper {
  .container {
    display: flex;
    flex-direction: column;
    border-radius: $borderRadius;
    overflow: hidden;
    padding: 20px 8px;
    position: relative;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;
    .title {
      width: 100%;
      padding-inline: 8px;
      color: #000;
      font-family: 'SF Pro Display', sans-serif;
      font-size: 16px;
      font-weight: 900;
      font-style: italic;
      line-height: 18px;
      text-align: left;
      margin-bottom: 4px;
      letter-spacing: 2px;
    }
    .secondRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .balance {
        padding-inline: 8px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 32px;
        font-weight: 500;
        line-height: 36px;
        color: #000;
        letter-spacing: -3%;
        .fractionalPart {
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .caretIcon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s ease;
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
        &:active {
          background-color: rgba(0,0,0,0.09);
        }
        &.expanded {
          transform: rotate(180deg);
        }
        svg {
          width: inherit;
          height: inherit;
        }
      }
    }
    .disabledOverlay {
      background-color: rgba(255,255,255,0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: not-allowed;
      display: flex;
      justify-content: center;
      align-items: center;
      .notAvailableContainer {
        padding: 5px 15px;
        background-color: white;
        border-radius: $borderRadius;
        .notAvailableText {
          font-size: 1.2rem;
          font-family: 'SF Pro Display', sans-serif;
          font-weight: 600;
        }
      }
      .loadingContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        .indicatorContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border-radius: 10px;
          padding: 5px;
        }
        .message {
          color: var(--primary);
          font-size: 0.9rem;
          margin-top: 5px;
        }
      }
    }
    .benefitsContainer {
      display: flex;
      flex-direction: column;
      padding-inline: 8px;
      .text {
        color: #000;
      }
      .actionsContainer {
        margin-top: 10px;
      }
    }
    .footer {
      width: 100%;
      gap: 10px;
      display: flex;
      flex-direction: column;
      .verificationContainer {
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        padding: 12px 16px 12px 16px;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 20px;
        user-select: none;
        .text {
          color: rgb(0, 0, 0);
          font-family: SF Pro Display, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0;
          flex-grow: 1;
          flex-shrink: 1;
        }
        .actionsContainer {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          gap: 15px;
          .btn {
            padding: 3px 10px;
            font-size: 10px;
            font-weight: 400;
          }
          .iconContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .itemsContainer {
    overflow: hidden;
    user-select: none;
    margin-top: 0;
    padding: 0;
    border-radius: 0 0 24px 24px;
    max-height: 0;
    transition: max-height 0.1s ease-out, margin-top 0.1s ease-out, padding 0.1s ease-out;
    &.expanded {
      margin-top: -30px;
      padding: 40px 0 10px 0;
      max-height: 500px;
      transition: max-height 0.1s ease-out, margin-top 0.1s ease-out, padding 0.1s ease-out;
    }
  }
 
}

.head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .icon{
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 34px;
    line-height: 1;
    z-index:  9999;
  }
}

