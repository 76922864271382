.authHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  padding: 16px 60px;
  .authHeaderTitleContainer {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .authHeaderTitle {
      font-weight: 500;
      font-size: 32px;
      font-family: 'Lora', sans-serif;
    }
  }
  .languagePicker {
    &.languagePicker {
      .ant-select-selector {
        padding: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .authHeaderContainer {
    padding: 16px;
    .authHeaderTitleContainer {
      .authHeaderTitle {
        font-size: 24px;
      }
    }
  }
}
