.wrapper {
  min-height: 100vh;
  width: 100%;
}

.text {
  margin-top: 16px;

  span {
    font-size: 16px;
    line-height: 20px;
  }
}

.number {
  text-wrap: nowrap;
}