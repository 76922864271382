.wrapper {
    cursor: pointer;
    padding: 16px;
    border-radius: 16px;
    height: 56px;
    background-color: rgba(0, 0, 0, 0.08);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.wrapper:hover {
    background-color: rgba(0, 0, 0, 0.10);
}

.text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #000;
    align-self: center;
}