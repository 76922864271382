.text {
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
}

.pin {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 900;
}

.button {
    margin-top: 16px;
}