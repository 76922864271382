.wrapper {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 16px;
}

.text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: #000;
    align-self: center;
    margin-bottom: 8px;
}