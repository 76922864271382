.card-dropdown {
  background-color: #efefef;
  border-radius: 16px;
  padding: 8px;

  .ant-dropdown-menu {
    background-color: unset;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .ant-dropdown-menu-item-selected {
    background-color: #0000000D !important;
  }
}