.title {
    width: 100%;
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 32px;
    font-weight: 900;
    font-style: italic;
    line-height: 32px;
    text-align: left;
    letter-spacing: 2px;
}

.fullWidth {
    width: 100%;
}