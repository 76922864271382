.accountContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
  }
  &:hover {
    border-color: var(--primary);
    &::after {
      background: rgba(0, 0, 0, 0.04);
      transition: 0.05s background ease-out;
    }
  }
  &:active {
    &::after {
      background: rgba(0, 0, 0, 0.03);
      transition: 0.05s background ease-out;
    }
  }
  .title {
    color: rgb(0, 0, 0);
    font-family:
      SF Pro Display,
      sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1px;
  }
  .balance {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -3%;
    text-align: left;
    font-family:
      SF Pro Display,
      sans-serif;
    color: #000;
    .fractionalPart {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.addAccount {
  padding: 5px;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  margin: 20px auto;
  transition: .3s;
  &:hover{
    background-color: #b9e3b5;
  }
}
