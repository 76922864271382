.wrapper {
    margin-top: 16px;
    width: 100%
}

.profile_data {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 24px;
}

.edit_btn {
    background-color: #F2F2F2;
    color: black;
    border: none;
    padding: 11px 24px !important;
}

.edit_btn:hover {
    background-color: #e7e7e7;
    color: black !important;
}