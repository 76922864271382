.wrapper {
  min-height: 100vh;
  width: 100%;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #333333;
  width: 40px;
  height: 40px;
}

.icon {
  svg {
    fill: #ffffff;
  }
}

.card {
  cursor: pointer;
}