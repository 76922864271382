.balance {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 52px;
    color: #000;
    letter-spacing: -3%;
}

.fractionalPart {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: rgba(0, 0, 0, 0.4);
}

.icon {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
}