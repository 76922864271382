.container {
    align-self: start;
    width: 100%;
}

.content {
    margin-bottom: 16px;
}

.no-padding {
    padding: 0;
}

.wrap {
    white-space: pre-line;
}

.hint {
    margin-top: 32px;
}