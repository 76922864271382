.wrapper {
  min-height: 100vh;
  width: 100%;
}

.text {
  margin-bottom: 16px;
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 20px;
}