.semibold {
    font-size: 18px;
    font-weight: 600;
}

.list-title {
    text-align: center;
    font-size: 18px;
    display: block;
    margin-bottom: 8px;
}

.fullWidth {
    width: 100%;
}

.warning-container {
    margin-top: 8px;
    gap: 8px;
    color: #faad14;
}

.warning-container span {
    color: #faad14;
}