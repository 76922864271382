.transaction {
  padding: 4px;
  border-radius: 4px;

  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }

  &__image {
    position: relative;
    background-color: #00000012;
    border-radius: 50%;
  }

  &__description {
    font-size: 12px;
    opacity: 0.5;
  }
}

.transaction-modal {
  width: 512px;
  border-radius: 24px;

  &__button, &__button:hover {
    border-color: black !important;
    color: black;
  }

  &__button:disabled {
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.transaction-details {
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 0px 4px 32px 0px #00000014;
  margin: 28px 24px 16px;

  &__header {
    padding: 16px 24px;
    background-color: #00000014;
    text-align: center;
  }

  &__body {
    padding: 12px 24px 24px;
  }

  &__title {
    font-size: 12px;
    opacity: 0.5;
  }

  &__text {

  }
}

.text {
  color: #000;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.balance {
  color: #000;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.transactions-block {
  cursor: pointer;
}

.no-break {
  word-break: keep-all;
}

.pay-again{
  padding: 0 !important;
  text-align: left;
}

.icon{
  display: block;
  width: 20px;
  height: 20px;
  color: #FD2121;
  position: absolute;
  bottom: -5px;
  right: -5px;
  &-wrap{
    position: relative;
  }
}