.w100 {
    width: 100%;
}
.mb0 {
    margin-bottom: 0;
}
.mt0 {
    margin-top: 0;
}
.m0 {
    margin: 0;
}
