.container {
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 72px;
  height: 44px;
  position: relative;
  overflow: hidden;
  border: 0.5px solid rgb(180, 180, 180);
  display: flex;
  flex-direction: column;
  padding: 4px;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
  }
  &:hover {
    border-color: var(--primary);
    &::after {
      background: rgba(255,255,255,0.1);
      transition: 0.05s background ease-out;
    }
  }
  &:active {
    border-color: var(--primary);
    &::after {
      background: rgba(0,0,0,0.03);
      transition: 0.05s background ease-out;
    }
  }
  .title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.08);
    font-family: SF Pro Display, sans-serif;
    font-style: italic;
    font-size: 22px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0;
    white-space: nowrap;
    text-align: center;
    user-select: none;
  }
  .infoRow {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    .cells {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 2px;
      .cell {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        .text {
          color: rgb(0, 0, 0);
          font-family: SF Pro Display, sans-serif;
          font-size: 12px;
          font-weight: 600;
          line-height: 12px;
          user-select: none;
        }
      }
    }
  }
}