.wrapper {
  margin-top: 16px;
}

.listItemWrapper {
  padding: 16px 8px;
  border-radius: 16px;
  cursor: pointer;
  transition: .4s;
}

.listItemWrapper:hover {
  background: rgba(0, 0, 0, 0.04);
}

.hint {
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.bold {
  font-size: 18px;
  font-weight: bold;
}

.slide-wrapper {
}

.phoneIcon {
  font-size: 24px;
  padding: 12px;
  box-sizing: 'content-box';
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
}

.phoneIconLarge {
  font-size: 36px;
  padding: 18px;
  box-sizing: 'content-box';
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  margin-bottom: 16px;
}

.drawerSubtitle {
  margin-bottom: 16px;
}

.drawerInput {
  margin-bottom: 32px;
}

.dividerContainer {
  padding: 0 16px
}

.divider {
  margin: -1px;
  background-color: rgba(0, 0, 0, 0.04);
}

.verified {
  color: rgba(21, 207, 116, 1);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.langugage-item {
  cursor: pointer;
}

.langugage-item-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}

.language-icon {
  font-size: 22px;
}