.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  &.transparent {
    background-color: transparent;

    .content .message {
      color: black;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .loaderContainer {
      padding: 10px;
      border-radius: 10px;
      background: white;
      margin-bottom: 10px;
      -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    }
    .message {
      color: white;
      font-size: 18px;
      text-align: center;
    }
  }
}