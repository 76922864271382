.fullWidth {
    width: 100%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
}

.address {
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
}

.cards {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    pointer-events: auto;
}

.carousel>div>div {
    overflow: visible !important;
}

.carousel li>button {
   background: black !important
}

.header{
    display: flex;
    width: 100%;
    align-items: center;
}

.header_title{
    flex: 1;
    text-align: center;
    font-weight: bold;
    padding: 0 20px;
    margin-right: 40px;
}