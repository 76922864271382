.wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    //max-width: 1400px;
    //margin: 0 auto;
    //min-height: 100vh;
    .contentContainer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}