.wrapper {
    margin: 32px 0;
}

.text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #000;
}

.item {
    cursor: pointer;
}

.item:first-child {
    align-items:center
}