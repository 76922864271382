.wrapper {
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    transition: background-color 0.2s;
    border-radius: 100px;
}

.pointer {
    cursor: pointer;
}

.pointer:hover::after {
    background-color: rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
 }
