.wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.description {
  margin: 24px 0;
}

.sendButton {
  background-color: #232323;

  &:hover:not(&:disabled) {
    background-color: #000000 !important;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.3);
  }
}


.input {
  width: 100%;
  //border: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  position: relative;
  z-index: 1;

  div, span {
    //border: none !important;
    border-right: none;
  }

  input {
    font-size: 24px !important;
    line-height: 38px !important;
    //border: none;
    border-right: none;
    //font-weight: 600;

    &::placeholder {
      opacity: 0.5;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
    }
  }
}

.select-popup {
  //margin-top: 100px;
  //top: 100px;
  //inset: auto auto -804px 412.812px !important;
  box-shadow: none;
  //width: fit-content;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.currency-selector {
  position: relative;
  border: none;

  &:before {
    height: 80%;
    content: '';
    width: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 10%;
    left: -18px;
    z-index: 1000;
  }
}