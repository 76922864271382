.button {
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  span {
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.button_primary:not(:disabled) {
  &:hover {
    background-color: #3d3d3d !important;
  }
}

.button_default:not(:disabled) {
  &:hover {
    border-color: #676767 !important;

    span {
      color: #676767 !important;
    }
  }
}