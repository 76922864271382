.unreadIcon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
}

.title{
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.notification {
  width: 100%;
  position: relative;
}

.notification__text {
  line-height: 22px;
  font-size: 16px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  //max-width: 200px;
  overflow: hidden;

  &_open {
    -webkit-line-clamp: unset;
  }
}

.badge{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FF2121;
  position: absolute;
  top: 16px;
  right: 16px;
}

.delete{
  position: absolute;
  right: -5px;
  top: -5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: #FF2121;
  cursor: pointer;
}