.title-wrapper {
    padding-bottom: 20px;
    border-bottom: 4px dotted white;
}

.plan-info-list {
    margin-top: 24px;
    margin-bottom: 28px;
}

.plan-info-list li {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
}

.button {
    color: black;
    border: 0;
}