.wrapper {
  min-height: 100vh;
  width: 100%;
}

.input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  input {
    font-size: 24px !important;
    line-height: 38px !important;
    //font-weight: 600;

    &::placeholder {
      opacity: 0.7;
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
    }
  }
}