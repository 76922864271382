.wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  position: relative;
  .container {
    width: 100%;
    max-width: 560px;
    .title {
      margin-bottom: 16px;
    }
    .list {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}